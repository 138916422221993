import Rest from '../Rest'

export default class PlansService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/plans'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  //Get Integration
  getPlanIntegration(planId: number) {
    return this.get(`/${planId}/integrations`)
  }

  updateIntegration(planId: number, payload: any) {
    const form={data_form :payload}
    return this.put(`/${planId}/integration`, form)
  }

  getPlanLimitTypes() {
    return this.get(`/plan_limit_types`)
  }

  //Save PeriodLimits
  savePeriodLimits(planId: number, payload: any) {
    const form={data_form :payload}
    return this.put(`/${planId}/periodLimits`, form)
  }

  deletePeriodLimits(planId: number, periodId: number) {
    return this.delete(`/${planId}/periodLimits/${periodId}`).then(
      (data) => {
        return data
      }
    )
  }

  //Periods
  getPlanPeriods(planId: number) {
    return this.get(`/${planId}/periods`)
  }

  savePlanPeriods(planId: number, payload: any) {
    const form={data_form :payload}
    return this.post(`/${planId}/periods`, form)
  }

  updatePlanPeriods(planId: number, id: number, payload: any) {
    const form={data_form :payload}
    return this.put(`/${planId}/periods/${id}`, form)
  }

  //Limits
  getPlanLimits(planId: number) {
    return this.get(`/${planId}/limits`)
  }

  savePlanLimitsPeriods(planId: number, payload: any) {
    const form={data_form :payload}
    return this.post(`/${planId}/limits`, form)
  }

  updatePlanLimits(planId: number, id: number, payload: any) {
    const form={data_form :payload}
    return this.put(`/${planId}/limits/${id}`, form)
  }

  clonePlan(planId: number, payload: any) {
    const form={data_form :payload}
    return this.put(`/${planId}/clone`, form)
  }

  //Get plan to p
  getPlanToPurchase() {
    return this.get(`/to_purchase`)
  }
}

